import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'ua', loadChildren: () => import('./@feature/unauthenticated/unauthenticated.module')
      .then(m => m.UnauthenticatedModule),
  },
  {
    path: 'a', loadChildren: () => import('./@feature/authenticated/authenticated.module')
      .then(m => m.AuthenticatedModule),
  },
  { path: '**', redirectTo: 'a/dashboard', pathMatch: 'full', },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
