import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { Router } from "@angular/router";
import { BrowserModule } from '@angular/platform-browser';
import { StorageService } from './@service/storage.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
// Firebase services + environment module
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AuthService } from './@service/auth.service';
// PrimeNG Modules
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';

import { LicenseManager } from 'ag-grid-enterprise';
import { MessageService } from 'primeng/api';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';

import * as Sentry from "@sentry/angular";

import { DatePipe } from '@angular/common';
LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-061426}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{IGOR}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{IGOR}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{IGOR}_need_to_be_licensed___{IGOR}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{10_June_2025}____[v3]_[01]_MTc0OTUxMDAwMDAwMA==358e3ec07a8b2789ccef316e139d3774")

@NgModule({
  declarations: [
    AppComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    // DO NOT ADD MORE IMPORTS HERE
    // ALL OTHER IMPORTS MUST BE ADDED IN THE INDIVIDUAL MODULE
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ToastModule,
    MessagesModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideCharts(withDefaultRegisterables()),
    AuthService,
    StorageService,
    MessageService,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {

}
